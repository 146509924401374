.donationMaincontainer .modal-content {
background-color: #212F3F;
border-top: 4px solid #A93025;
top: 80px;
color: white;
.close{
  color: white;
}
.modal-header{
  border-bottom: 0px;
  padding-left: 30px;
  padding-right: 30px;
 
}
.modal-body{
  padding: 30px;
  img{
    width:100%;
  }
  .donationHeader{
    h5{
      font-size: 16px;
      margin-bottom: 11px;
    }
  }
  .donationDetails{
    h5{
      font-size: 18px;
      font-weight: bold;
      // margin-left: auto;
      // margin-right: 50px;
    }
  }
}
.submitTitle{
  display: flex;
  flex-direction: column;
  justify-content: center;
  h5{
    margin-bottom: 20px;
    font-size: 16px;
  }
}
.submitButton{
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: 110px;
  button{
    margin-bottom: 10px;
    
        background-color:#008FDF;
        border-radius:25px;
        color:white;
        width:auto;
        font-weight:bold;
       padding: 10px;
       border: none;
        font-size: 14px;
        cursor:pointer;
        
       
  }

}
}
@mixin transition {
    @for $i from 1 through 50 {
      &:nth-child(#{$i}) {
        transition: transform 1s #{$i * .08}s cubic-bezier(.29,1.4,.44,.96);
      }
    }
}
  
@mixin alpha-attribute($attribute, $color, $background) {
$percent: alpha($color) * 100%;
$opaque: opacify($color, 1);
$solid-color: mix($opaque, $background, $percent);
#{$attribute}: $solid-color;
#{$attribute}: $color;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
//   background-color:#1B212C;
  $main: #1B212C;
  $text: #FEFEFE;
  $contrast: darken($main, 10%);

input.hamburger {
    display: none;
    &:checked {
      & ~ .drawer-list {
         transform: translatex(0);
          border-bottom-left-radius: 0;
          li {
            transform: translatex(0);
            @include transition;
            a {
              padding-right: 15px;
            }
          }
      }
      & ~ label {
        > i {
          background-color: transparent;
          transform: rotate(90deg);
          &:before {
            transform: translate(-50%, -50%) rotate(315deg);
          }
          &:after {
            transform: translate(-50%, -50%) rotate(-315deg);
          }
        }
      }
    }
}
  
label.hamburger {
    z-index: 12;
    position: relative;
    display: block;
    height: 30px;
    width: 30px;
    margin: 10px;
    &:hover {
      cursor: pointer;
    }
    > i {
      position: absolute;
      width: 100%;
      height: 2px;
      top: 50%;
      background-color: $text;
      pointer-events: auto;
      transition-duration: .35s;
      transition-delay: .35s;
      &:before,
      &:after {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        left: 50%;
        background-color: $text;
        content: "";
        transition: transform 0.35s;
        transform-origin: 50% 50%;
      }
      &:before {
        transform: translate(-50%, -10px);
      }
      &:after {
        transform: translate(-50%, 10px);
      }
    }
}
  
label.hamburger {
    // position: fixed;
    // top: 15px;
    // right: 15px;
}