$main:#1B212C;
@mixin alpha-attribute($attribute, $color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    #{$attribute}: $solid-color;
    #{$attribute}: $color;
}
.drawer-list {
    position: fixed;
    right: 10%;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10;
    transform: translate(100vw, 0);
    /* ie workaround */
    -ms-transform: translatex(-100vw);
    box-sizing: border-box;
    pointer-events: none;
    padding-top: 125px;
    transition: width 475ms ease-out, transform 450ms ease, border-radius .8s .1s ease;
    border-bottom-left-radius: 100vw;
    @include alpha-attribute('background-color', rgba($main, 0.8), white);
    @media (min-width: 768px) {
      width: 100vw;
    }
    ul {
      padding-right: 10%;
      padding-top: 5%;
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
      overflow: auto;
      overflow-x: hidden;
      pointer-events: auto;
    }
    li {
      list-style: none;
      text-transform: uppercase;
      pointer-events: auto;
      white-space: nowrap;
      box-sizing: border-box;
      transform: translatex(100vw);
      /* ie workaround */
      -ms-transform: translatex(-100vw);
      &:last-child {
        margin-bottom: 2em;
      }
      a {

        text-decoration: none;
        color: #ffffff;
        text-align: center;
        display: block;
        padding: 1rem;
        font-size: calc(24px - 0.02vw);
        @media (min-width: 768px) {
          text-align: right;
          padding: .5rem;
        }
        &:hover {
          cursor: pointer;
          color: #0A92D6;
        //   @include alpha-attribute('background-color', rgba($main, 0.5), white);
        }
      }
    }
  }